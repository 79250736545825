<script>
export default {
  name: 'VerticalMenuSubItem',
  components: {
    Icon: () => import('@/components/general/Icon')
  },
  props: {
    text: {
      type: String,
      default: ''
    },
    link: {
      type: String,
      default: '#'
    },
    icon: {
      type: String,
      default: ''
    }
  }
}
</script>

<template>
  <li class="submenu-item">
    <router-link
      :to="link"
      class="menu-link"
      exact
    >
      <icon
        :name="icon"
        wrapper
        size="medium"
      />
      <span class="text">{{ $t(text) }}</span>
    </router-link>
  </li>
</template>
